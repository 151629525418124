<!-- Nav Menu -->

<template>
  <div id="app">
    <Menu @season="setSeason" :foot='false' :config="env" style="display:none"/>
    <transition name="fade" appear> <router-view :key="$route.fullPath" :season='season' :config='env'/></transition>
    <Footer :config="env" style="display:none"/>
  </div>
</template>

<style>
a {
  color: #337ab7!important;
  text-decoration: none;
  background-color: transparent;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s;
}
 
.fade-enter-active {
  transition-delay: .5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
#app {
    font-family: 'Gantari', sans-serif!important;
  font-size: 14pt;
  font-weight: 500;
  /* background-color: rgb(249, 249, 249); */
}
li.nav-item::marker {
  color: transparent;
}

@media only screen and (max-width: 480px) {
  #app {
    font-size: 10pt;
  }
}

/*ProximaNova Font*/

@font-face {
font-family: 'proxima-nova, sans-serif';
font-style: normal;
font-weight: normal;
src: local('ProximaNova Extrabold'), url('./assets/fonts/ProximaNova-Extrabold.otf') format('otf');
}

@font-face {
font-family: 'ProximaNova Black';
font-style: normal;
font-weight: normal;
src: local('ProximaNova Black'), url('./assets/fonts/ProximaNova-Black.otf') format('otf');
}

@font-face {
font-family: 'ProximaNova Bold';
font-style: normal;
font-weight: normal;
src: local('ProximaNova Bold'), url('./assets/fonts/ProximaNova-Bold.otf') format('otf');
}

@font-face {
font-family: 'ProximaNova RegItalic';
font-style: normal;
font-weight: normal;
src: local('ProximaNova RegItalic'), url('./assets/fonts/ProximaNova-RegItalic.otf') format('otf');
}

@font-face {
font-family: 'ProximaNova RegularItalic';
font-style: normal;
font-weight: normal;
src: local('ProximaNova RegularItalic'), url('./assets/fonts/ProximaNova-RegularItalic.otf') format('otf');
}

@font-face {
font-family: 'ProximaNova SboldItalic';
font-style: normal;
font-weight: normal;
src: local('ProximaNova SboldItalic'), url('./assets/fonts/ProximaNova-SboldItalic.otf') format('otf');
}

@font-face {
font-family: 'ProximaNova Sbold';
font-style: normal;
font-weight: normal;
src: local('ProximaNova Sbold'), url('./assets/fonts/ProximaNova-Sbold.otf') format('otf');
}

@font-face {
font-family: 'ProximaNova Reg';
font-style: normal;
font-weight: normal;
src: local('ProximaNova Reg'), url('./assets/fonts/ProximaNova-Reg.otf') format('otf');
}

@font-face {
font-family: 'ProximaNova SemiboldItalic';
font-style: normal;
font-weight: normal;
src: local('ProximaNova SemiboldItalic'), url('./assets/fonts/ProximaNova-SemiboldItalic.otf') format('otf');
}

@font-face {
font-family: 'ProximaNova Semibold';
font-style: normal;
font-weight: normal;
src: local('ProximaNova Semibold'), url('./assets/fonts/ProximaNova-Semibold.otf') format('otf');
}

@font-face {
font-family: 'ProximaNova Regular';
font-style: normal;
font-weight: normal;
src: local('ProximaNova Regular'), url('./assets/fonts/ProximaNova-Regular.otf') format('otf');
}

@font-face {
font-family: 'ProximaNova LightItalic';
font-style: normal;
font-weight: normal;
src: local('ProximaNova LightItalic'), url('./assets/fonts/ProximaNova-LightItalic.otf') format('otf');
}

@font-face {
font-family: 'ProximaNova Light';
font-style: normal;
font-weight: normal;
src: local('ProximaNova Light'), url('./assets/fonts/ProximaNova-Light.otf') format('otf');
}
/* End ProximaNova Font*/

@font-face {
font-family: 'Gotham Book';
font-style: normal;
font-weight: normal;
src: local('Gotham Book'), url('./assets/fonts/GothamBook.woff') format('woff');
}


@font-face {
font-family: 'Gotham Book Italic';
font-style: normal;
font-weight: normal;
src: local('Gotham Book Italic'), url('./assets/fonts/GothamBookItalic.woff') format('woff');
}


@font-face {
font-family: 'Gotham Light';
font-style: normal;
font-weight: normal;
src: local('Gotham Light'), url('./assets/fonts/GothamLight.woff') format('woff');
}

@font-face {
font-family: 'Gotham Light';
font-style: normal;
font-weight: normal;
src: local('Gotham Light'), url('./assets/fonts/Gotham-Light.woff') format('woff');
}

@font-face {
font-family: 'Gotham Light Italic';
font-style: normal;
font-weight: normal;
src: local('Gotham Light Italic'), url('./assets/fonts/GothamLightItalic.woff') format('woff');
}


@font-face {
font-family: 'Gotham Medium';
font-style: normal;
font-weight: normal;
src: local('Gotham Medium'), url('./assets/fonts/GothamMedium.woff') format('woff');
}


@font-face {
font-family: 'Gotham Medium';
font-style: normal;
font-weight: normal;
src: local('Gotham Medium'), url('./assets/fonts/GothamMedium_1.woff') format('woff');
}


@font-face {
font-family: 'Gotham Medium';
font-style: normal;
font-weight: normal;
src: local('Gotham Medium'), url('./assets/fonts/GothamMediumItalic.woff') format('woff');
}


@font-face {
font-family: 'Gotham Bold';
font-style: normal;
font-weight: normal;
src: local('Gotham Bold'), url('./assets/fonts/GothamBold.woff') format('woff');
}


@font-face {
font-family: 'Gotham Bold';
font-style: normal;
font-weight: normal;
src: local('Gotham Bold'), url('./assets/fonts/Gotham-Bold.woff') format('woff');
}


@font-face {
font-family: 'Gotham Bold Italic';
font-style: normal;
font-weight: normal;
src: local('Gotham Bold Italic'), url('./assets/fonts/GothamBoldItalic.woff') format('woff');
}


@font-face {
font-family: 'Gotham Black Regular';
font-style: normal;
font-weight: normal;
src: local('Gotham Black Regular'), url('./assets/fonts/Gotham-Black.woff') format('woff');
}


@font-face {
font-family: 'Gotham Light Regular';
font-style: normal;
font-weight: normal;
src: local('Gotham Light Regular'), url('./assets/fonts/Gotham-Light.woff') format('woff');
}


@font-face {
font-family: 'Gotham Thin Regular';
font-style: normal;
font-weight: normal;
src: local('Gotham Thin Regular'), url('./assets/fonts/Gotham-Thin.woff') format('woff');
}


@font-face {
font-family: 'Gotham XLight Regular';
font-style: normal;
font-weight: normal;
src: local('Gotham XLight Regular'), url('./assets/fonts/Gotham-XLight.woff') format('woff');
}


@font-face {
font-family: 'Gotham Book Italic';
font-style: normal;
font-weight: normal;
src: local('Gotham Book Italic'), url('./assets/fonts/Gotham-BookItalic.woff') format('woff');
}


@font-face {
font-family: 'Gotham Thin Italic';
font-style: normal;
font-weight: normal;
src: local('Gotham Thin Italic'), url('./assets/fonts/Gotham-ThinItalic.woff') format('woff');
}


@font-face {
font-family: 'Gotham Ultra Italic';
font-style: normal;
font-weight: normal;
src: local('Gotham Ultra Italic'), url('./assets/fonts/Gotham-UltraItalic.woff') format('woff');
}


@font-face {
font-family: 'Gotham XLight Italic';
font-style: normal;
font-weight: normal;
src: local('Gotham XLight Italic'), url('./assets/fonts/Gotham-XLightItalic.woff') format('woff');
}
@media (min-width: 1200px){
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1279px!important;
  }
}
</style>

<script>
import Menu from "@/components/menu.vue";
import Footer from "@/components/footer.vue";

export default {
  components: {
    Menu,
    Footer
  },
  data(){
    return{
      course:'',
      currentReport:'',
      season:'',
      env:[]
    }
  },
      methods: {
        // Define method that will use the payload to update the data property
            setSeason(payload) {
            this.season = payload.season
        },

    },
    mounted(){
      this.env=process.env
    }
};
</script>
