<template>
  <div class="menu">
    <div v-if="foot === false">
      <div id="nav" class="mobHide">
        <b-navbar type="light" id="topNav" class="darkNav">
          <b-container class="TopLinksMenu">
            <b-navbar-nav>
              <b-nav-item
                class="menuLink headLink Toplink"
                v-on:click="isHidden = false"
              >
                SEARCH
              </b-nav-item>
              <b-nav-item
                v-b-modal.modal-1
                class="menuLink headLink Toplink"
                href="#"
              >
                SUBSCRIBE
              </b-nav-item>
              <b-modal
                content-class="subscribe"
                hide-header
                centered
                hide-footer
                class="subscribe"
                id="modal-1"
              >
                <b-img
                  class="subImg"
                  :src="
                    config.VUE_APP_WP_DIGITAL_OCEAN +
                      '2019/05/signup-background.png'
                  "
                ></b-img>
                <div class="work">
                  <p>
                    Want to recieve exclusive news, videos, tournament
                    highlights, golf important tips and more ?
                  </p>
                  <b-form>
                    <b-row>
                      <b-col>
                        <b-form-input
                          v-model="sub.Email"
                          placeholder="Email"
                          id="email"
                          type="email"
                        ></b-form-input>
                      </b-col>
                      <b-col>
                        <b-button class="link" type="submit"
                          >Subscribe</b-button
                        >
                      </b-col></b-row
                    >
                  </b-form>
                </div>
              </b-modal>
              <span style="display:flex;" v-if="!isHidden">
                <input v-model="text" placeholder="Enter your name" />
                <b-button
                  v-on:click="isHidden = true"
                  :to="{
                    name: 'search',
                    query: { search: text },
                  }"
                  class="sIcon"
                >
                  <font-awesome-icon :icon="['fa', 'search']" />
                </b-button>
              </span>
            </b-navbar-nav>
            <b-navbar-nav class="ml-auto tp">
              <b-nav-item
                :href="
                  'https://www.youtube.com/channel/' + config.VUE_APP_YOUTUBE_ID
                "
              >
                <font-awesome-icon
                  class="icon iconTopLink"
                  :icon="['fab', 'youtube']"
                />
              </b-nav-item>
              <b-nav-item
                :href="
                  'https://www.facebook.com/' + config.VUE_APP_FACEBOOK_NAME
                "
              >
                <font-awesome-icon
                  class="icon iconTopLink"
                  :icon="['fab', 'facebook']"
                />
              </b-nav-item>
              <b-nav-item
                :href="'https://twitter.com/' + config.VUE_APP_TWITTER_NAME"
              >
                <font-awesome-icon
                  class="icon iconTopLink"
                  :icon="['fab', 'twitter']"
                />
              </b-nav-item>
              <b-nav-item
                :href="'https://www.instagram.com/' + config.VUE_APP_INSTA_NAME"
              >
                <font-awesome-icon
                  class="icon iconTopLink"
                  :icon="['fab', 'instagram']"
                />
              </b-nav-item>
              <b-nav-item
                class="menuLink headLink ToplinkRight"
                to="/about/media-centre"
                >MEDIA CENTRE</b-nav-item
              >
              <b-nav-item
                class="menuLink headLink ToplinkRight"
                href="https://members.pga.org.au/"
                target="_blank"
                >MyPGA</b-nav-item
              >
            </b-navbar-nav>
          </b-container>
        </b-navbar>
      </div>
      <div id="nav">
        <b-navbar type="dark" toggleable="lg" id="bottomNav">
          <b-container>
            <b-navbar-nav>
              <b-navbar-brand to="/">
                <b-img
                  class="siteLogo"
                  :src="
                    config.VUE_APP_ASSETS + '2020/11/PGA_CORP_LOGO_MONO_W.png'
                  "
                ></b-img>
              </b-navbar-brand>
            </b-navbar-nav>

            <b-navbar-toggle
              class="ml-auto"
              type="dark"
              v-b-modal.nav-collapse
            ></b-navbar-toggle>
            <b-navbar-nav>
              <b-collapse is-nav class="m-auto">
                <b-nav-item
                  @mouseover="onOver0"
                  @mouseleave="onLeave0"
                  class="menuLink"
                  :to="{
                    name: 'reports',
                    query: {
                      url: currentAus(),
                      id: seasons,
                      code: courseAus,
                      tour: 'aus',
                    },
                  }"
                  >Leaderboard
                  <b-nav-item-dropdown
                    id="my-nav-dropdown"
                    ref="dropdown0"
                    toggle-class="nav-link-custom"
                  >
                    <b-dropdown-item
                      :to="{
                        name: 'reports',
                        query: {
                          url: currentAus(),
                          id: seasons,
                          code: courseAus,
                          tour: 'aus',
                        },
                      }"
                      >ISPS HANDA PGA Tour of Australia</b-dropdown-item
                    >
                    <b-dropdown-item
                      :to="{
                        name: 'reports',
                        query: {
                          url: currentSnr(),
                          id: seasonsSNR,
                          code: courseSnr,
                          tour: 'snr',
                        },
                      }"
                      >SParms PGA Legends Tour</b-dropdown-item
                    >
                    <b-dropdown-item
                      :to="{
                        name: 'reports',
                        query: {
                          url: currentAll(),
                          id: seasonsALL,
                          code: courseAll,
                          tour: trnState,
                        },
                      }"
                      >adidas PGA Pro-AM Series</b-dropdown-item
                    >
                    <b-dropdown-item
                      :to="{
                        name: 'reports',
                        query: {
                          url: currentTrn(),
                          id: seasonsTRN,
                          code: courseTrn,
                          tour: 'trn',
                        },
                      }"
                      >MPP Tournaments</b-dropdown-item
                    >
                    <b-dropdown-item
                      :to="{
                        name: 'reports',
                        query: {
                          url: currentPpc(),
                          id: seasonsPPC,
                          code: coursePpc,
                          tour: 'ppc',
                        },
                      }"
                      >PGA Professionals Championship</b-dropdown-item
                    >
                  </b-nav-item-dropdown>
                </b-nav-item>
                <b-nav-item
                  @mouseover="onOver1"
                  @mouseleave="onLeave1"
                  class="menuLink"
                  :to="{
                    name: 'schedule',
                    query: { id: seasons, tour: 'aus' },
                  }"
                  >Schedule
                  <b-nav-item-dropdown
                    id="my-nav-dropdown"
                    ref="dropdown1"
                    toggle-class="nav-link-custom"
                  >
                    <b-dropdown-item
                      :to="{
                        name: 'schedule',
                        query: { id: seasons, tour: 'aus' },
                      }"
                      >ISPS HANDA PGA Tour of Australia</b-dropdown-item
                    >
                    <b-dropdown-item
                      :to="{
                        name: 'schedule',
                        query: { id: seasonsSNR, tour: 'snr' },
                      }"
                      >SParms PGA Legends Tour</b-dropdown-item
                    >
                    <b-dropdown-item
                      :to="{
                        name: 'schedule',
                        query: { id: seasonsALL, tour: 'all' },
                      }"
                      >adidas PGA Pro-AM Series</b-dropdown-item
                    >
                    <b-dropdown-item
                      :to="{
                        name: 'schedule',
                        query: { id: seasonsTRN, tour: 'trn' },
                      }"
                      >MPP Tournaments</b-dropdown-item
                    >
                    <b-dropdown-item
                      :to="{
                        name: 'schedule',
                        query: { id: seasonsPPC, tour: 'ppc' },
                      }"
                      >PGA Professionals Championship</b-dropdown-item
                    >
                    <b-dropdown-item
                      :to="{
                        name: 'qschool',
                        query: { wppage: '12605' },
                      }"
                      >Qualifying School</b-dropdown-item
                    >
                  </b-nav-item-dropdown>
                </b-nav-item>
                <b-nav-item
                  @mouseover="onOver2"
                  @mouseleave="onLeave2"
                  class="menuLink"
                  to="/news"
                  >News
                  <b-nav-item-dropdown
                    id="my-nav-dropdown"
                    text="News"
                    ref="dropdown2"
                    toggle-class="nav-link-custom"
                  >
                    <b-dropdown-item to="/news">Latest News</b-dropdown-item>
                    <b-dropdown-item to="/pga-pro"
                      >PGA Professionals</b-dropdown-item
                    >
                  </b-nav-item-dropdown>
                </b-nav-item>
                <b-nav-item class="menuLink" :href="'https://www.youtube.com/user/pgaofaustralia?app=desktop'" target="_blank">PGATV</b-nav-item>
                <b-nav-item
                  @mouseover="onOver3"
                  @mouseleave="onLeave3"
                  class="menuLink"
                  to="/education"
                  >Education
                  <b-nav-item-dropdown
                    id="my-nav-dropdown"
                    text="Education"
                    ref="dropdown3"
                    toggle-class="nav-link-custom"
                  >
                    <b-dropdown-item to="/education/academy"
                      >PGA Education Department</b-dropdown-item
                    >
                    <b-dropdown-item to="/education/membership-pathway-program"
                      >PGA Membership Pathway Program</b-dropdown-item
                    >
                    <b-dropdown-item
                      to="/education/pga-international-golf-institute"
                      >PGA International Golf Insitute</b-dropdown-item
                    >
                    <b-dropdown-item
                      to="/education/pga-centre-for-learning-and-performance"
                      >PGA Centre for Learning and Performance</b-dropdown-item
                    >
                    <b-dropdown-item
                      to="/education/community-instructor-program"
                      >Community Instructor Program</b-dropdown-item
                    >
                  </b-nav-item-dropdown>
                </b-nav-item>
                <!-- <b-nav-item class="menuLink" to="/findPro"
                  >Find A PGA Pro</b-nav-item
                > -->

                <b-nav-item
                  @mouseover="onOver6"
                  @mouseleave="onLeave6"
                  class="menuLink"
                  to="/findPro"
                  >Find A PGA Pro
                  <b-nav-item-dropdown
                    id="my-nav-dropdown"
                    text="Find a pro"
                    ref="dropdown6"
                    toggle-class="nav-link-custom"
                  >
                    <b-dropdown-item to="/findPro">Directory</b-dropdown-item>
                    <b-dropdown-item to="/findPro/benefits-of-hiring-a-pga-professional">Benefits of hiring a PGA Professional</b-dropdown-item>
                    <b-dropdown-item to="/findPro/recruitment-services">Recruitment Services</b-dropdown-item>
                    <b-dropdown-item to="/findPro/ace-program">ACE Program</b-dropdown-item>
                  </b-nav-item-dropdown>
                </b-nav-item>
                <b-nav-item
                  @mouseover="onOver4"
                  @mouseleave="onLeave4"
                  class="menuLink"
                  to="/play"
                  >Play
                  <b-nav-item-dropdown
                    id="my-nav-dropdown"
                    text="Play"
                    ref="dropdown4"
                    toggle-class="nav-link-custom"
                  >
                    <b-dropdown-item
                      to="/play/juniors"
                      >Juniors</b-dropdown-item
                    >
                    <b-dropdown-item
                      href="https://www.golf.org.au/find-a-golf-club"
                      target="_blank"
                      >Where Can I Play?</b-dropdown-item
                    >
                    <b-dropdown-item
                      to="/play/the-scramble"
                      >Volkswargan Scramble</b-dropdown-item
                    >
                    <b-dropdown-item
                     to="/play/all-abilities-coaches"
                      >All Ability Coaches</b-dropdown-item
                    >
                    <b-dropdown-item
                      to="/play/first-tee-australia"
                      >First Tee Australia</b-dropdown-item
                    >
                    <b-dropdown-item
                      to="/play/womens-golf-network"
                      >Womens Golf Network</b-dropdown-item
                    >
                  </b-nav-item-dropdown>
                </b-nav-item>
                <b-nav-item
                  @mouseover="onOver5"
                  @mouseleave="onLeave5"
                  class="menuLink"
                  to="/about"
                  >About
                  <b-nav-item-dropdown
                    id="my-nav-dropdown"
                    text="About"
                    ref="dropdown5"
                    toggle-class="nav-link-custom"
                  >
                    <b-dropdown-item
                     to="/about/our-members"
                      >Members</b-dropdown-item
                    >
                    <b-dropdown-item
                      to="/about/governance"
                      >Governance</b-dropdown-item
                    >
                    <b-dropdown-item
                      to="/about/commercial-sponsorship-and-advertising"
                      >Partners</b-dropdown-item
                    >
                    <b-dropdown-item
                      to="/about/media-information"
                      >Media</b-dropdown-item
                    >
                    <b-dropdown-item
                      to="/about/awards"
                      >Awards</b-dropdown-item
                    >
                    <b-dropdown-item
                      to="/about/mobile"
                      >Offical PGA APP</b-dropdown-item
                    >
                    <b-dropdown-item
                      to="/about/contact"
                      >Contact Us</b-dropdown-item
                    >
                  </b-nav-item-dropdown>
                </b-nav-item>
                <b-nav-item
                  class="menuLink mobShow"
                  href="https://members.pga.org.au/"
                  target="_blank"
                  >MyPGA</b-nav-item
                >
              </b-collapse>
              <b-modal hide-header hide-footer id="nav-collapse">
                <div class="mobileMenu">
                  <b-navbar-brand @click="$bvModal.hide('nav-collapse')" to="/">
                    <b-img
                      class="siteLogoMenu"
                      :src="
                        config.VUE_APP_ASSETS +
                          '2020/11/PGA_CORP_LOGO_MONO_W.png'
                      "
                    ></b-img>
                  </b-navbar-brand>
                  <b-dropdown
                    id="dropdown-1"
                    text="Leaderboard"
                    class="m-md-2 menuLink"
                  >
                    <b-dropdown-item
                      @click="$bvModal.hide('nav-collapse')"
                      :to="{
                        name: 'reports',
                        query: {
                          url: currentAus(),
                          id: seasons,
                          code: courseAus,
                          tour: 'aus',
                        },
                      }"
                      >ISPS HANDA PGA Tour of Australia</b-dropdown-item
                    >
                    <b-dropdown-item
                      @click="$bvModal.hide('nav-collapse')"
                      :to="{
                        name: 'reports',
                        query: {
                          url: currentSnr(),
                          id: seasonsSNR,
                          code: courseSnr,
                          tour: 'snr',
                        },
                      }"
                      >SParms PGA Legends Tour</b-dropdown-item
                    >
                    <b-dropdown-item
                      @click="$bvModal.hide('nav-collapse')"
                      :to="{
                        name: 'reports',
                        query: {
                          url: currentAll(),
                          id: seasonsALL,
                          code: courseAll,
                          tour: trnState,
                        },
                      }"
                      >adidas PGA Pro-AM Series</b-dropdown-item
                    >
                    <b-dropdown-item
                      @click="$bvModal.hide('nav-collapse')"
                      :to="{
                        name: 'reports',
                        query: {
                          url: currentTrn(),
                          id: seasonsTRN,
                          code: courseTrn,
                          tour: 'trn',
                        },
                      }"
                      >MPP Tournaments</b-dropdown-item
                    >
                    <b-dropdown-item
                      @click="$bvModal.hide('nav-collapse')"
                      :to="{
                        name: 'reports',
                        query: {
                          url: currentPpc(),
                          id: seasonsPPC,
                          code: coursePpc,
                          tour: 'ppc',
                        },
                      }"
                      >PGA Professionals Championship</b-dropdown-item
                    >
                  </b-dropdown>
                  <b-dropdown
                    id="dropdown-2"
                    text="Schedule"
                    class="m-md-2 menuLink"
                  >
                    <b-dropdown-item
                      @click="$bvModal.hide('nav-collapse')"
                      :to="{
                        name: 'schedule',
                        query: { id: seasons, tour: 'aus' },
                      }"
                      >ISPS HANDA PGA Tour of Australia</b-dropdown-item
                    >
                    <b-dropdown-item
                      @click="$bvModal.hide('nav-collapse')"
                      :to="{
                        name: 'schedule',
                        query: { id: seasonsSNR, tour: 'snr' },
                      }"
                      >SParms PGA Legends Tour</b-dropdown-item
                    >
                    <b-dropdown-item
                      @click="$bvModal.hide('nav-collapse')"
                      :to="{
                        name: 'schedule',
                        query: { id: seasonsALL, tour: trnState },
                      }"
                      >adidas PGA Pro-AM Series</b-dropdown-item
                    >
                    <b-dropdown-item
                      @click="$bvModal.hide('nav-collapse')"
                      :to="{
                        name: 'schedule',
                        query: { id: seasonsTRN, tour: 'trn' },
                      }"
                      >MPP Tournaments</b-dropdown-item
                    >
                    <b-dropdown-item
                      @click="$bvModal.hide('nav-collapse')"
                      :to="{
                        name: 'schedule',
                        query: { id: seasonsPPC, tour: 'ppc' },
                      }"
                      >PGA Professionals Championship</b-dropdown-item
                    >
                    <b-dropdown-item
                      @click="$bvModal.hide('nav-collapse')"
                      :to="{
                        name: 'qschool',
                        query: { wppage: '12605' },
                      }"
                      >Qualifying School</b-dropdown-item
                    >
                  </b-dropdown>
                  <b-dropdown
                    id="dropdown-3"
                    text="News"
                    class="m-md-2 menuLink"
                  >
                    <b-dropdown-item
                      @click="$bvModal.hide('nav-collapse')"
                      to="/news"
                      >Latest News</b-dropdown-item
                    >
                    <b-dropdown-item
                      @click="$bvModal.hide('nav-collapse')"
                      to="/pga-pro"
                      >PGA Professionals</b-dropdown-item
                    >
                  </b-dropdown>
                  <b-nav-item
                    @click="$bvModal.hide('nav-collapse')"
                    class="menuLink"
                    to="/video"
                    >PGATV</b-nav-item
                  >
                  <b-dropdown
                    id="dropdown-4"
                    text="Education"
                    class="m-md-2 menuLink"
                  >
                    <b-dropdown-item
                      @click="$bvModal.hide('nav-collapse')"
                      :to="{
                        name: 'education',
                        query: { wppage: '91', pin: 'pgaacademy' },
                      }"
                      >PGA Education Department</b-dropdown-item
                    >
                    <b-dropdown-item
                      @click="$bvModal.hide('nav-collapse')"
                      :to="{
                        name: 'education',
                        query: { wppage: '93' },
                      }"
                      >PGA International Golf Insitute</b-dropdown-item
                    >
                    <b-dropdown-item
                      @click="$bvModal.hide('nav-collapse')"
                      :to="{
                        name: 'education',
                        query: { wppage: '95' },
                      }"
                      >PGA Centre for Learning and Performance</b-dropdown-item
                    >
                    <b-dropdown-item
                      @click="$bvModal.hide('nav-collapse')"
                      :to="{
                        name: 'education',
                        query: { wppage: '97' },
                      }"
                      >Community Instructor Program</b-dropdown-item
                    >
                  </b-dropdown>
                  <b-nav-item
                    @click="$bvModal.hide('nav-collapse')"
                    class="menuLink"
                    to="/findPro"
                    >Find A PGA Pro</b-nav-item
                  >
                  <b-dropdown
                    id="dropdown-5"
                    text="Play"
                    class="m-md-2 menuLink"
                  >
                    <b-dropdown-item
                      @click="$bvModal.hide('nav-collapse')"
                      :to="{
                        name: 'play',
                        query: { wppage: '103' },
                      }"
                      >Juniors</b-dropdown-item
                    >
                    <b-dropdown-item
                      @click="$bvModal.hide('nav-collapse')"
                      href="https://www.golf.org.au/find-a-golf-club"
                      target="_blank"
                      >Where Can I Play?</b-dropdown-item
                    >
                    <b-dropdown-item
                      @click="$bvModal.hide('nav-collapse')"
                      :to="{
                        name: 'play',
                        query: { wppage: '107' },
                      }"
                      >Volkswargan Scramble</b-dropdown-item
                    >
                    <b-dropdown-item
                      @click="$bvModal.hide('nav-collapse')"
                      :to="{
                        name: 'play',
                        query: { wppage: '115' },
                      }"
                      >All Ability Coaches</b-dropdown-item
                    >
                    <b-dropdown-item
                      @click="$bvModal.hide('nav-collapse')"
                      :to="{
                        name: 'play',
                        query: { wppage: '20158' },
                      }"
                      >First Tee Australia</b-dropdown-item
                    >
                    <b-dropdown-item
                      @click="$bvModal.hide('nav-collapse')"
                      :to="{
                        name: 'play',
                        query: { wppage: '21058' },
                      }"
                      >Womens Golf Network</b-dropdown-item
                    >
                  </b-dropdown>
                  <b-dropdown
                    @click="$bvModal.hide('nav-collapse')"
                    id="dropdown-6"
                    text="About"
                    class="m-md-2 menuLink"
                  >
                    <b-dropdown-item
                      @click="$bvModal.hide('nav-collapse')"
                      :to="{
                        name: 'about',
                        query: { wppage: '7772' },
                      }"
                      >Members</b-dropdown-item
                    >
                    <b-dropdown-item
                      @click="$bvModal.hide('nav-collapse')"
                      :to="{
                        name: 'about',
                        query: { wppage: '7775', pin: 'governance' },
                      }"
                      >Governance</b-dropdown-item
                    >
                    <b-dropdown-item
                      @click="$bvModal.hide('nav-collapse')"
                      :to="{
                        name: 'about',
                        query: { wppage: '7781' },
                      }"
                      >Partners</b-dropdown-item
                    >
                    <b-dropdown-item
                      @click="$bvModal.hide('nav-collapse')"
                      :to="{
                        name: 'about',
                        query: { wppage: '7779' },
                      }"
                      >Media</b-dropdown-item
                    >
                    <b-dropdown-item
                      @click="$bvModal.hide('nav-collapse')"
                      :to="{
                        name: 'about',
                        query: { wppage: '8666', pin: 'awards' },
                      }"
                      >Awards</b-dropdown-item
                    >
                    <b-dropdown-item
                      @click="$bvModal.hide('nav-collapse')"
                      :to="{
                        name: 'about',
                        query: { wppage: '7786' },
                      }"
                      >Offical PGA APP</b-dropdown-item
                    >
                    <b-dropdown-item
                      @click="$bvModal.hide('nav-collapse')"
                      :to="{
                        name: 'about',
                        query: { wppage: '35' },
                      }"
                      >Contact Us</b-dropdown-item
                    >
                  </b-dropdown>
                  <b-nav-item
                    @click="$bvModal.hide('nav-collapse')"
                    class="menuLink mobShow"
                    href="https://members.pga.org.au/"
                    target="_blank"
                    >MyPGA</b-nav-item
                  >
                </div>
              </b-modal>
            </b-navbar-nav>
            <b-navbar-nav>
              <b-navbar-brand
                href="https://members.pga.org.au/"
                target="_blank"
              >
                <b-img
                  class="siteLogoRight"
                  :src="
                    config.VUE_APP_ASSETS +
                      '2021/03/PGA_MEM_PRO_LOGO_CMYK_POS.png'
                  "
                ></b-img>
              </b-navbar-brand>
            </b-navbar-nav>
          </b-container>
        </b-navbar>
      </div>
    </div>
    <div v-if="foot === true">
      <b-row class="mobHide">
        <b-col class="col-md-3">
          <b-nav-item
            class="footLink"
            :to="{
              name: 'reports',
              query: {
                url: currentAus(),
                id: seasons,
                code: courseAus,
                tour: 'aus',
              },
            }"
            >ISPS HANDA PGA Tour of Australasia</b-nav-item
          >
          <b-nav-item
            class="footLink"
            :to="{
              name: 'reports',
              query: {
                url: currentSnr(),
                id: seasonsSNR,
                code: courseSnr,
                tour: 'snr',
              },
            }"
            >SParms PGA Legends Tour</b-nav-item
          >
          <b-nav-item
            class="footLink"
            :to="{
              name: 'reports',
              query: {
                url: currentAll(),
                id: seasonsALL,
                code: courseAll,
                tour: trnState,
              },
            }"
            >adidas PGA Pro-Am Series</b-nav-item
          >
          <b-nav-item
            class="footLink"
            :to="{
              name: 'reports',
              query: {
                url: currentTrn(),
                id: seasonsTRN,
                code: courseTrn,
                tour: 'trn',
              },
            }"
            >MPP Tournaments</b-nav-item
          >
          <b-nav-item
            class="footLink"
            :to="{
              name: 'reports',
              query: {
                url: currentPpc(),
                id: seasonsPPC,
                code: coursePpc,
                tour: 'ppc',
              },
            }"
            >PGA Professionals Championship</b-nav-item
          >
          <b-nav-item class="footLink" to="/schedule"
            >Major Championships</b-nav-item
          >
          <b-nav-item
            class="footLink"
            :to="{
              name: 'qschool',
              query: { wppage: '12605' },
            }"
            >Qualifying School</b-nav-item
          >
        </b-col>
        <b-col class="col-md-2">
          <b-nav-item
            class="footLink"
            :to="{
              name: 'play',
              query: { wppage: '15', pin: 'play' },
            }"
            >Play</b-nav-item
          >
          <b-nav-item
            class="footLink"
            :to="{
              name: 'play',
              query: { wppage: '20158' },
            }"
            >First Tee Australia</b-nav-item
          >
          <b-nav-item
            class="footLink"
            :to="{
              name: 'play',
              query: { wppage: '103' },
            }"
            >Juniors</b-nav-item
          >
          <b-nav-item
            class="footLink"
            href="https://www.golf.org.au/find-a-golf-club"
            target="_blank"
            >Where can I play?</b-nav-item
          >
          <b-nav-item
            class="footLink"
            :to="{
              name: 'play',
              query: { wppage: '107' },
            }"
            >Volkswagen Scramble</b-nav-item
          >
          <b-nav-item
            class="footLink"
            :to="{
              name: 'about',
              query: { wppage: '7786' },
            }"
            >Official PGA App</b-nav-item
          >
        </b-col>
        <b-col class="col-md-3">
          <b-nav-item
            class="footLink"
            :to="{
              name: 'education',
              query: { wppage: '17', pin: 'education' },
            }"
            >Education</b-nav-item
          >
          <b-nav-item
            class="footLink"
            :to="{
              name: 'education',
              query: { wppage: '91', pin: 'pgaacademy' },
            }"
            >PGA Education Department</b-nav-item
          >
          <b-nav-item
            class="footLink"
            :to="{
              name: 'education',
              query: { wppage: '93' },
            }"
            >PGA International Golf Institute</b-nav-item
          >
          <b-nav-item
            class="footLink"
            :to="{
              name: 'education',
              query: { wppage: '95' },
            }"
            >PGA Centre for Learning and Performance</b-nav-item
          >
          <b-nav-item
            class="footLink"
            :to="{
              name: 'education',
              query: { wppage: '97' },
            }"
            >Community Instructor Program</b-nav-item
          >
          <b-nav-item
            class="footLink"
            :to="{
              name: 'education',
              query: { wppage: '99' },
            }"
            >PGA Golf Expo</b-nav-item
          >
        </b-col>
        <b-col class="col-md-2">
          <b-nav-item class="footLink" to="/news">News</b-nav-item>
          <b-nav-item class="footLink" to="/news"
            >ISPS HANDA PGA Tour of Australasia</b-nav-item
          >
          <b-nav-item class="footLink" to="/news"
            >International News</b-nav-item
          >
          <b-nav-item class="footLink" to="/news"
            >adidas PGA Pro-Am Series</b-nav-item
          >
          <b-nav-item class="footLink" to="/news"
            >SParms PGA Legends Tour</b-nav-item
          >
          <b-nav-item class="footLink" to="/news">The 19th</b-nav-item>
        </b-col>
        <b-col class="col-md-2">
          <b-nav-item class="footLink" to="/video">PGA TV</b-nav-item>
          <b-nav-item class="footLink" to="/findPro">Find a PGA Pro</b-nav-item>
          <b-nav-item
            class="footLink"
            :to="{
              name: 'about',
              query: { wppage: '33' },
            }"
            >About</b-nav-item
          >

          <b-nav-item
            class="footLink"
           to="/about/media-centre"
            >Media Centre</b-nav-item
          >
          <b-nav-item
            class="footLink"
            :to="{
              name: 'about',
              query: { wppage: '12706' },
            }"
            >Media Accreditation</b-nav-item
          >
        </b-col>
      </b-row>
      <b-row class="mobShow">
        <b-nav-item
          class="footLink"
          :to="{
            name: 'reports',
            query: {
              url: currentAus(),
              id: seasons,
              code: courseAus,
              tour: 'aus',
            },
          }"
          >Tournament</b-nav-item
        >
        <b-nav-item
          class="footLink"
          :to="{
            name: 'play',
            query: { wppage: '15', pin: 'play' },
          }"
          >Play</b-nav-item
        >
        <b-nav-item
          class="footLink"
          :to="{
            name: 'education',
            query: { wppage: '17', pin: 'education' },
          }"
          >Education</b-nav-item
        >
        <b-nav-item class="footLink" to="/news">News</b-nav-item>
        <b-nav-item class="footLink" to="/video">PGA TV</b-nav-item>
        <b-nav-item class="footLink" to="/findPro">Find a PGA Pro</b-nav-item>
      </b-row>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Menu",
  props: ["foot", "config"],
  data() {
    return {
      sub: { Email: "" },
      menueLink: "menuLink",
      seasons: [],
      seasonsALL: [],
      seasonsSNR: [],
      seasonsTRN: [],
      seasonsPPC: [],
      courseAus: [],
      reportsAus: [],
      courseAll: [],
      reportsAll: [],
      courseSnr: [],
      reportsSnr: [],
      coursePpc: [],
      reportsPpc: [],
      courseTrn: [],
      reportsTrn: [],
      trnState: [],
      isHidden: true,
      text: "",
      fullAusdata: [],
      fullSnrdata: [],
      fullPpcdata: [],
      fullAlldata: [],
      fullTrndata: []
    };
  },
  methods: {
    currentAus: function() {
      if (Array.isArray(this.reportsAus))
        if (this.reportsAus[this.reportsAus.length - 1].report_title === 'Live Scoring Monitor' || this.reportsAus[this.reportsAus.length - 1].report_title === 'Course Statistics' || this.reportsAus[this.reportsAus.length - 1].report_title === 'Scoring Statistics' || this.reportsAus[this.reportsAus.length - 1].report_title === 'Statistics Tables') {
          if (this.fullAusdata.rounds_played === '1') {
            return this.reportsAus[this.reportsAus.length - 5].report_url;     
          } else {
            return this.reportsAus[this.reportsAus.length - 4].report_url;
          }
        } else {
          return this.reportsAus[this.reportsAus.length - 1].report_url;
        }
      else return this.reportsAus.report_url;
    },
    currentAll: function() {
      if (Array.isArray(this.reportsAll))
        if (this.reportsAll[this.reportsAll.length - 1].report_title === 'Live Scoring Monitor' || this.reportsAll[this.reportsAll.length - 1].report_title === 'Course Statistics' || this.reportsAll[this.reportsAll.length - 1].report_title === 'Scoring Statistics' || this.reportsAll[this.reportsAll.length - 1].report_title === 'Statistics Tables') {
          if (this.fullAlldata.rounds_played === '1') {
            return this.reportsAll[this.reportsAll.length - 5].report_url;
          } else {
            return this.reportsAll[this.reportsAll.length - 4].report_url;
          }
        } else {
          return this.reportsAll[this.reportsAll.length - 1].report_url;
        }
      else return this.reportsAll.report_url;
    },
    currentSnr: function() {
      if (Array.isArray(this.reportsSnr))
        if (this.reportsSnr[this.reportsSnr.length - 1].report_title === 'Live Scoring Monitor' || this.reportsSnr[this.reportsSnr.length - 1].report_title === 'Course Statistics' || this.reportsSnr[this.reportsSnr.length - 1].report_title === 'Scoring Statistics' || this.reportsSnr[this.reportsSnr.length - 1].report_title === 'Statistics Tables') {
          if (this.fullSnrdata.rounds_played === '1') {
            return this.reportsSnr[this.reportsSnr.length - 5].report_url;
          } else {
            return this.reportsSnr[this.reportsSnr.length - 4].report_url;
          }
        } else {
          return this.reportsSnr[this.reportsSnr.length - 1].report_url;
        }
      else return this.reportsSnr.report_url;
    },
    currentTrn: function() {
      if (Array.isArray(this.reportsTrn))
        if (this.reportsTrn[this.reportsTrn.length - 1].report_title === 'Live Scoring Monitor' || this.reportsTrn[this.reportsTrn.length - 1].report_title === 'Course Statistics' || this.reportsTrn[this.reportsTrn.length - 1].report_title === 'Scoring Statistics' || this.reportsTrn[this.reportsTrn.length - 1].report_title === 'Statistics Tables') {
          if (this.fullTrndata.rounds_played === '1') {
            return this.reportsTrn[this.reportsTrn.length - 5].report_url;
          } else {
            return this.reportsTrn[this.reportsTrn.length - 4].report_url;
          }
        } else {
          return this.reportsTrn[this.reportsTrn.length - 1].report_url;
        }
      else return this.reportsTrn.report_url;
    },
    currentPpc: function() {
      if (Array.isArray(this.reportsPpc))
        if (this.reportsPpc[this.reportsPpc.length - 1].report_title === 'Live Scoring Monitor' || this.reportsPpc[this.reportsPpc.length - 1].report_title === 'Course Statistics' || this.reportsPpc[this.reportsPpc.length - 1].report_title === 'Scoring Statistics' || this.reportsPpc[this.reportsPpc.length - 1].report_title === 'Statistics Tables') {
          if (this.fullPpcdata.rounds_played === '1') {
            return this.reportsPpc[this.reportsPpc.length - 5].report_url;
          } else {
            return this.reportsPpc[this.reportsPpc.length - 4].report_url;
          }
        } else {
          return this.reportsPpc[this.reportsPpc.length - 1].report_url;
        }
      else return this.reportsPpc.report_url;

    },
    onOver0() {
      this.$refs.dropdown0.visible = true;
    },
    onLeave0() {
      this.$refs.dropdown0.visible = false;
    },
    onOver1() {
      this.$refs.dropdown1.visible = true;
    },
    onLeave1() {
      this.$refs.dropdown1.visible = false;
    },
    onOver2() {
      this.$refs.dropdown2.visible = true;
    },
    onLeave2() {
      this.$refs.dropdown2.visible = false;
    },
    onOver3() {
      this.$refs.dropdown3.visible = true;
    },
    onLeave3() {
      this.$refs.dropdown3.visible = false;
    },
    onOver4() {
      this.$refs.dropdown4.visible = true;
    },
    onLeave4() {
      this.$refs.dropdown4.visible = false;
    },
    onOver5() {
      this.$refs.dropdown5.visible = true;
    },
    onLeave5() {
      this.$refs.dropdown5.visible = false;
    },
    onOver6() {
      this.$refs.dropdown6.visible = true;
    },
    onLeave6() {
      this.$refs.dropdown6.visible = false;
    },
  },
  mounted() {
    axios
      .get(
        process.env.VUE_APP_TIC_BASE +
          process.env.VUE_APP_AUS +
          "/tmticx?randomadd=" +
          new Date().getTime()
      )
      .then((response) => {
        this.seasons = response.data.tm_params.season_code;
        this.courseAus = response.data.code;
        this.reportsAus = response.data.reports.reports_entry;
        this.fullAusdata = response.data;
        this.$emit("season", { season: this.seasons });
        return axios.get(
          process.env.VUE_APP_TIC_BASE +
            process.env.VUE_APP_ALL +
            "/tmticx?randomadd=" +
            new Date().getTime()
        );
      })
      .then((response) => {
        this.seasonsALL = response.data.tm_params.season_code;
        this.$emit("season", { season: this.seasonsALL });
        this.courseAll = response.data.code;
        this.reportsAll = response.data.reports.reports_entry;
        this.fullAlldata = response.data;
        this.trnState = response.data.class_group.toLowerCase();
        return axios.get(
          process.env.VUE_APP_TIC_BASE +
            process.env.VUE_APP_SNR +
            "/tmticx?randomadd=" +
            new Date().getTime()
        );
      })
      .then((response) => {
        this.seasonsSNR = response.data.tm_params.season_code;
        this.$emit("season", { season: this.seasonsSNR });
        this.courseSnr = response.data.code;
        this.reportsSnr = response.data.reports.reports_entry;
        this.fullSnrdata = response.data;
        return axios.get(
          process.env.VUE_APP_TIC_BASE +
            process.env.VUE_APP_TRN +
            "/tmticx?randomadd=" +
            new Date().getTime()
        );
      })
      .then((response) => {
        this.seasonsTRN = response.data.tm_params.season_code;
        this.$emit("season", { season: this.seasonsTRN });
        this.courseTrn = response.data.code;
        this.reportsTrn = response.data.reports.reports_entry;
        this.fullTrndata = response.data;
        return axios.get(
          process.env.VUE_APP_TIC_BASE +
            process.env.VUE_APP_PPC +
            "/tmticx?randomadd=" +
            new Date().getTime()
        );
      })
      .then((response) => {
        this.seasonsPPC = response.data.tm_params.season_code;
        this.$emit("season", { season: this.seasonsPPC });
        this.coursePpc = response.data.code;
        this.reportsPpc = response.data.reports.reports_entry;
        this.fullPpcsdata = response.data;
      });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.TopLinksMenu {
  margin-top: -0.9em;
  margin-bottom: -1em;
}
.iconTopLink {
  font-size: 16pt;
  position: relative;
  top: 8px;
}
.Toplink {
  position: relative;
  top: 3px;
}
.ToplinkRight {
  position: relative;
  top: 11px;
}
.siteLogo {
  width: 80px;
  position: inherit;
  top: 16px;
  right: 80px;
}

.siteLogoRight {
  max-width: 60px;
  float: right;
  min-width: 60px;
  padding-top: 5px;
  padding-bottom: 10px;
}
.menuLink {
  padding: 0 10px;
}
.headLink > .nav-link {
  color: #fff;
  font-size: 8pt !important;
}

.menuLink > .nav-link {
  color: white !important;
  font-weight: 500;
  font-size: 14px;
}

::v-deep .dropdown-item {
  color: white !important;
  font-weight: 500;
  font-family: 'Gantari', sans-serif!important;
  padding: 0.55rem 1.5rem;
}

::v-deep .dropdown-item:hover {
  color: #00b588 !important;
  background-color: #00433e;
}

::v-deep .dropdown-menu {
  background-color: #00433e;
}

::v-deep .dropdown-toggle::after {
  display: none;
}
::v-deep .dropdown-toggle {
  font-size: 14px;
}
::v-deep .dropdown-toggle > span {
  color: white !important;
  font-weight: 500;
  font-family: 'Gantari', sans-serif!important;
}
.menuLink > .nav-link:hover {
  color: #00b588 !important;
}
.work {
  margin: -170px -100px 0 150px;
  color: white;
}
.subImg {
  margin-left: -50px;
  margin-top: -50px;
}
.link {
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  max-width: 180px;
  font-weight: 300;
  text-align: center;
  background: rgb(0, 67, 62);
  margin: -4px 0px;
  padding: 10px;
}
.subscribe {
  width: 140%;
  height: 350px;
  background-color: #00433e !important;
}
.icon {
  padding-bottom: 7px;
  color: white;
}
.sIcon {
  background-color: #00433e;
  color: white !important;
}
#topNav {
  background-color: #292929;
  padding-top: 12px;
  padding-bottom: 15px;
}

#bottomNav {
  background-color: #00433e;
}
::v-deep .dropdown > a {
  display: none;
}
.footLink > a {
  font-size: 9pt;
}
li {
  list-style-type: none;
}
li > a {
  color: white !important;
}
.mobShow {
  display: none;
}

@media only screen and (max-width: 1024px) {
  #nav-collapse {
    padding-left: 0;
  }
}
@media only screen and (max-width: 990px) {
  .ul.dropdown-menu.show > li {
    margin-top: 15px;
  }
}
@media only screen and (max-width: 768px) {
  .mobHide {
    display: none;
  }
  .mobShow {
    display: block;
    text-align: center;
  }
  .footLink > a {
    font-size: 16px;
  }
  .menuLink.mobShow {
    text-align: left;
  }
  .btn-group {
    position: relative;
    display: block;
  }

  .mobileMenu {
    position: absolute;
    padding: 0;
    background-color: #00433e;
    top: -30px;
    left: -140px;
    height: 100vh;
    width: 100vh;
  }
  .siteLogoMenu {
    width: 180px;
    padding: 20px 35px;
  }
  ::v-deep .dropdown-toggle {
    background-color: transparent;
    border: 0;
  }

  .menuLink {
    padding: 6px 26px;
  }

  ::v-deep .menuLink > button {
    font-size: 20px;
  }

  .menuLink > .nav-link {
    font-size: 18px;
  }
}
@media only screen and (max-width: 480px) {
  .greenNav {
    display: none;
  }

  #nav-collapse {
    padding-left: 0;
  }

  #logo {
    width: 90%;
  }
  .mobileMenu {
    top: -9px;
    left: -9px;
  }
}
</style>
