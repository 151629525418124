<template>
  <div>
    <h2 class="socailFeed">Social Feed</h2>
    <b-row>
      <b-col
        lg="2"
        md="2"
        v-for="(insta, index) in insta.data.slice(0, 18)"
        :key="index"
        class="instaHeight"
      >
        <a :href="insta.permalink" target="_blank" rel="noopener" class="instaPic">
          <template v-if="insta.media_type === 'VIDEO'">
            <div class="instaInner-pic" :style="'background-image: url('+insta.thumbnail_url+');'">
            </div>    
          </template>
          <template v-else-if="insta.media_type === 'CAROUSEL_ALBUM' || insta.media_type === 'IMAGE'">
            <div class="instaInner-pic" :style="'background-image: url('+insta.media_url+');'">
            </div>            
          </template>
        </a>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "instafeed",
  props:['config'],
  data() {
    return {
      insta: [],
    };
  },
  mounted() {
    const api = process.env.VUE_APP_INSTAGRAM_URL+process.env.VUE_APP_INSTAGRAM_KEY;
    axios //add API Call
      .get(api) // Page number changed on each page based on JSON page id you are calling
      .then((response) => {
        this.insta = response.data;
      });
  },
};
</script>

<style scoped>
h2.socailFeed {
  padding-bottom: 20px;
}
.title{
  font-size: 25px;
  font-weight: 400;
  font-family: Helvetica,Roboto,"Segoe UI",Calibri,sans-serif;
}
.playCircle {
  font-size: 63px;
  color: #ffffff;
  margin-top: 66px;
}
.instaInner-pic {
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  background-color: #263056;
  overflow: hidden;
  width: 100%;
  object-fit: cover;
  height: 200px;
}
a.instaPic {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  display: grid;
}

.instaHeight {
  overflow: hidden;
  width: 100%;
  object-fit: cover;
  height: 200px;
}
.fa-gradient {
  color: #9c47fc;
  background: -webkit-linear-gradient(#9c47fc, #1e60e4);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@media only screen and (max-width: 2560px) {
  .instaHeight{
    overflow: hidden;
    width: 100%;
    object-fit: cover;
    height: 200px;
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 1440px) {
  .playCircle {
    font-size: 100px;
    color: #ffffff;
    margin-top: 38px;
  }
}
@media only screen and (max-width: 1024px) {
  .instaHeight {
    height: 300px;
  }
  .playCircle {
    font-size: 70px;
    color: #ffffff;
    margin-top: 37px;
  }
  .instaInner-pic{
    background-position: left;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    background-color: #263056;
    overflow: hidden;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    height: 300px;
  }
}

@media only screen and (max-width: 480px) {

  .instaHeight{
    height: 300px;
  }
  .playCircle{
    margin-top: 100px;
  }
}
</style>
