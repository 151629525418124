<template>
  <b-row>
    <b-col v-for="(upComing, index) in futureTour" :key="index" lg="4" sm="12" class="upEvents">
        <div class='box'>
          <b-card tag="article">
            <b-link class="noHover" :to="{
              name: 'tournament',
              query: { id: seasonCode, code: upComing.code, tour: tour },
            }">
              <b-card-text>
                <span v-if="upComing.sponsor_logo !== ''">
                  <b-img class="sponLogo" :src="upComing.sponsor_logo"></b-img>
                </span>
                <span v-else-if="tour === 'aus'">
                  <b-img
                    class="sponLogo"
                    :src="config.VUE_APP_ASSETS+'2021/03/PGA_TOUR_LOGO_PRI_RGB_POS.png'"
                  ></b-img>
                </span>
                <span v-else-if="tour === 'snr'">
                  <b-img
                    class="sponLogo"
                    :src="config.VUE_APP_ASSETS+'2021/08/PGA_LT_LOGO_RGB_POS.png'"
                  ></b-img>
                </span>
                <span v-else-if="tour === 'all'">
                  <b-img
                    class="sponLogo"
                    :src="config.VUE_APP_ASSETS+'2021/04/PGA_PAS_ADI_LOGO_RGB_POS.png'"
                  ></b-img>
                </span>
                <span v-else-if="tour === 'trn'">
                  <b-img
                    class="sponLogo"
                    :src="config.VUE_APP_ASSETS+'2021/03/PGA_CORP_LOGO_RGB_POS.png'"
                  ></b-img>
                </span>
                <span v-else-if="tour === 'ppc'">
                  <b-img
                    class="sponLogo"
                    :src="config.VUE_APP_PPC_LOGO+'2021/8133/8133.jpg'"
                  ></b-img>
                </span>
                <p class="date">
                  {{ upComing.dates }}
                </p>
                <p class="name">
                  {{ upComing.full_name }}
                </p>
                <p class="course">
                  {{ upComing.course }}
                </p>
              </b-card-text>
            </b-link>
            <div v-for="(report, index) in upComing.reports" :key="index">
                <template v-if="report.report_title == 'Tournament Entries'">
                  <b-nav-item
                    class="reportButton"
                    :to="{
                      name: 'reports',
                      query: {
                        url: report.report_url,
                        id: seasonCode,
                        code: upComing.code,
                        tour: config.VUE_APP_URL_TOUR,
                        title: report.report_title
                      },
                    }">
                    <span class="LinkNames">
                      Entries
                       <img class="rightLittleArrow" src="https://wp-ocssport.ocs-sport.com/wp-content/uploads/2024/07/chevron-right-copy.png">
                    </span>
                </b-nav-item>
                </template>
                <template v-else>
                  <b-nav-item
                    class="reportButton"
                    :to="{
                      name: 'reports',
                      query: {
                        url: report.report_url,
                        id: seasonCode,
                        code: upComing.code,
                        tour: config.VUE_APP_URL_TOUR,
                        title: report.report_title
                      },
                    }">
                    <span class="LinkNames">
                      {{report.report_title}}
                       <img class="rightLittleArrow" src="https://wp-ocssport.ocs-sport.com/wp-content/uploads/2024/07/chevron-right-copy.png">
                    </span>
                  </b-nav-item>
                </template>
            </div>
          </b-card>
        </div>
    </b-col>
    <b-col v-for='(extra, index) in extraCol' :key='index'>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "upComingTours",
  props: ["futureTour", 'tour', 'config', 'seasonCode'],
  methods: {
    proAm: function(){
      if (this.$route.query.tour === 'all')
      return this.tourinfo.class_group.toLowerCase()
      else
      return this.$route.query.tour
    },
  },
  computed:{
    extraCol(needed){
      var array = this.futureTour.length
      var total = 3
      needed = total - array
      return needed
    }
  }
};
</script>

<style scoped>
.LinkNames {
  color: #003C52;
  font-size: 14px;
  font-weight: 600;
}
.noHover {
  text-decoration: none;
}
.sponLogo {
  display: block;
  max-height: 90px;
  /* width: 100%; */
  margin: auto;
  text-align: center;
  padding-bottom: 10px;
  /* max-width: 125px; */
  margin-top: 5px !important;
  margin-bottom: 10px !important;
}
.date {
  color: black;
  font-weight: 300;
  font-size: 14px;
  font-family: 'Gantari', sans-serif!important;
  text-align: center;
  margin-bottom: 0;
}
.name {
  margin: auto;
  font-size: 14px;
  font-weight: 600;
  color: #000;
  text-align: center;
  margin-bottom: 0px;
  /* white-space: nowrap; */
  /* width: 200px; */
  /* overflow: hidden; */
  /* text-overflow: ellipsis; */
  text-align: center;
  line-height: 2;
}
.course {
  font-weight: 300;
  font-size: 14px;
  text-align: center;
  margin-bottom: 0px;
  color: #000;
}
.box {
  display: block;
  top: 0px;
  border-radius: 4px;
  position: relative;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
}

.box:hover {
  transition: all 0.3s ease;
  box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
  top: -4px;
  background-color: white;
}
::v-deep .card{
  /* height: 250px; */
    /* height: 232px; */
    height: 290px;
}
::v-deep .card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 15px;
}
li.nav-item.reportButton {
  position: absolute;
  bottom: 0;
  right: 0;
}
@media only screen and (max-width: 768px) {
  .card {
    height: 250px;
    width: 100%;
  }
  .upEvents {
    margin-bottom: 20px!important;
  }
}
</style>
