<template>
  <div class="footer">
    <b-container>
    <div id='insta'>
      <InstaFeed :config="config"/>
    </div>
    </b-container>
    <b-container>
      <span class="partners premierPartners">
        PGA Premier Partners
      </span>
      <b-row :class="logoRow">
        <b-col v-for="(logos, index) in footerData.slice().reverse()" :key="index">
          <b-img :class="logoImg" :src="logos.acf.image" />
        </b-col>
      </b-row>
      <b-row :class="logoRowMobile">
        <b-col cols='6' v-for="(logos, index) in footerData.slice(0, 5)" :key="index">
          <b-img :class="logoImg" :src="logos.acf.image" />
        </b-col>
      </b-row>
      <b-row :class="logoRowMobile">
        <b-col cols='6'  v-for="(logos, index) in footerData.slice(5, 10)" :key="index">
          <b-img :class="logoImg" :src="logos.acf.image" />
        </b-col>
      </b-row>
      <b-nav-item
        :to="{
          name: 'about',
          query: { wppage: '7781' },
        }"
      >
        <h2 class="partners">
          View All
          <font-awesome-icon class="icon" :icon="['fa', 'angle-right']" />
        </h2>
      </b-nav-item>
    </b-container>
    <div id="green">
      <b-container>
        <b-row class="siteMap">
          <b-col lg='6' md='12' cols='12'>
            <b-row class="footerPad-Download">
              <b-col md='4' cols='12'>
                <p class="download">
                  DOWNLOAD THE OFFICIAL PGA APP
                </p>
              </b-col>
              <b-col lg='4' md='4' cols='6'>
                <a :href="config.VUE_APP_GOOGLE_PLAY">
                <b-img
                  class="appImg"
                  :src="config.VUE_APP_WP_DIGITAL_OCEAN+'2019/07/playgoogletwo.png'"
                ></b-img>
                </a>
              </b-col>
              <b-col lg='4' md='4' cols='6'>
                <a :href='config.VUE_APP_APPLE_STORE'>
                <b-img
                  class="appImg"
                  :src="config.VUE_APP_WP_DIGITAL_OCEAN+'2019/07/applestortwo.png'"
                ></b-img>
                </a>
              </b-col>
            </b-row>
          </b-col>
          <b-col lg='6' md='12' cols='12'>
            <div class="footerPad-Download connectRight">
              <span class="footerSocial">
                  <span class="connectUs">Connect With Us</span>
                  <a :href="'https://www.youtube.com/channel/'+config.VUE_APP_YOUTUBE_ID" target="_blank" >
                    <font-awesome-icon class="icon socailIcon" :icon="['fab', 'youtube']" />
                  </a>
                  <a :href="'https://www.facebook.com/'+config.VUE_APP_FACEBOOK_NAME" target="_blank" >
                    <font-awesome-icon class="icon socailIcon" :icon="['fab', 'facebook']" />
                  </a>
                  <a :href="'https://twitter.com/'+config.VUE_APP_TWITTER_NAME" target="_blank" >
                    <font-awesome-icon class="icon socailIcon" :icon="['fab', 'twitter']" />
                  </a>
                  <a :href="'https://www.instagram.com/'+config.VUE_APP_INSTA_NAME" target="_blank" >
                      <font-awesome-icon
                        class="icon socailIcon"
                        :icon="['fab', 'instagram']"
                      />
                  </a>
              </span>
            </div>
          </b-col>
        </b-row>
        <Menu :foot="true" :config="config"/>
      </b-container>
        <div class="ocs">
          <b-container>
            <b-row>
              <b-col>
                <b-media right-align vertical-align="center">
                  <template #aside>
                    <a href="https://ocs-sport.com" target="_blank">
                      <b-img
                        :class="ocsLogo"
                        :src="config.VUE_APP_WP_DIGITAL_OCEAN+'2019/02/OCS_Software_Logo_2017_WO.png'"
                      />
                    </a>
                  </template>
                  <p class="mb-0 copyRight">
                    © 2021 PGA of Australia | Powered by
                  </p>
                </b-media>
              </b-col>
              <b-col>
                <b-navbar type="dark">
                  <b-navbar-nav class="ml-auto bottomMenu">
                    <b-nav-item
                      class="bottomRight"
                      :to="{
                        name: 'about',
                        query: { wppage: '59' },
                      }"
                      >Terms and Conditions</b-nav-item
                    >
                    <b-nav-item
                      class="bottomRight"
                      :to="{
                        name: 'about',
                        query: { wppage: '57' },
                      }"
                      >Privacy Policy</b-nav-item
                    >
                    <b-nav-item
                      class="bottomRight"
                      :to="{
                        name: 'about',
                        query: { wppage: '8764' },
                      }"
                      >Advertise with us</b-nav-item
                    >
                    <b-nav-item
                      class="bottomRight"
                      :to="{
                        name: 'about',
                        query: { wppage: '35' },
                      }"
                      >Contact Us</b-nav-item
                    >
                  </b-navbar-nav>
                </b-navbar>
              </b-col>
            </b-row>
          </b-container>
        </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Menu from "@/components/menu.vue";
import InstaFeed from "@/components/instaFeed.vue";
export default {
  name: "Footer",
  components: {
    Menu,
    InstaFeed
  },
  props:['config'],
  data() {
    return {
      footerData: [],
      logoImg: "logoImg",
      ocsLogo: "ocsLogo",
      logoRow: "logoRow",
      logoRowMobile: "logoRowMobile",
    };
  },
  mounted() {
    axios
      .get(process.env.VUE_APP_WPAPI_URL+"wp/v2/Sponsors/")
      .then((response) => (this.footerData = response.data));
  },
};
</script>

<style scoped>
.connectUs {
  margin-right: 3em;
}
.socailIcon {
  font-size: 18px!important;
  color: #fff;
  margin-right: 25px;
}
.connectRight {
  float: right;
  color: #fff;
  margin-top: 7px;
  font-size: 12pt;
}
.bottomMenu {
  position: relative;
  top: 0px;
}
.footerPad-Download {
  padding-bottom: 2em;
}
span.footerSocial {
  display: contents;
  float: right;
}
.indiFooter-social {
  width: 20px;
  max-width: 45px;
}
::v-deep .footLink > a {
  font-size: 9pt;
  padding-left: 0;
}
span.premierPartners {
  font-weight: bold;
  padding-top: 3em;
}
.footer {
  padding-top: 2%;
  background-color: #fff;
}
#insta{
  border-bottom: 1px solid #868686;
  padding: 20px 0;
}
.partners {
  text-align: center;
  display: block;
  font-weight: bold;
  color: black;
  padding-bottom: 15px;
  padding-top: 20px;
  font-size: 14px;
}
.logoRowMobile {
  display: none;
}

.logoImg {
  width: 80%;
}
#green {
  background-color: #00433e;
  padding-top: 20px;
}
.logoRow {
  padding-top: 0%;
  padding-bottom: 1%;
  text-align: center;
  margin-right: 0;
}
.copyRight {
  color: #ffffff;
  font-family: "Roboto", sans-serif !important;
  font-size: 8pt;
}
.ocsLogo {
  width: 50px;
  margin-top: -3px;
}
.media-body {
  flex: inherit;
  padding-top: 15px;
}
.bottomRight {
  font-size: 9pt;
}
.bottomRight > a {
  color: #fff !important;
}
.bottomRight > a:hover {
  color: #8dc544 !important;
}
.ocs {
  border-top: 1px solid #868686;
  padding-bottom: 19px;
  padding-top: 23px;
  margin-top: 40px;
}
.appImg {
  width: 130px;
  margin-top: 10px;
}
.download {
  color: #fff;
  font-weight: 700;
  font-size: 9pt;
  margin-top: 11px;
  width: 125px;
}
.connect {
  float: right;
  color: #fff;
  margin-top: 11px;
  font-size: 9pt;
}
li {
  list-style-type: none;
}
li > a {
  color: white !important;
}
.menuLink > a {
  font-size: 9pt;
}
@media only screen and (max-width: 768px) {
  .connect{
    float: left;
  }
  .icon{
    font-size: 26px;
  }

}
@media only screen and (max-width: 480px) {
  .logoRow {
    display: none;
  }
  .logoRowMobile {
    display: flex;
    padding-top: 4%;
    padding-bottom: 4%;
    text-align: center;
    margin-left: 0;
    margin-right: 0;
  }

}
</style>
